import appointmentsReducer from "./appointments.js";
import cartReducer from "./cart";
import contactReducer from "./contact.js";
import newsletterReducer from "./newsletter.js";
import productsReducer from "./products.js";
import questionsReducer from "./questions.js";
import userReducer from "./user";


const reducer = {
  cart: cartReducer,
  user: userReducer,
  products: productsReducer,
  newsletter: newsletterReducer,
  contact: contactReducer,
  questions: questionsReducer,
  appointments: appointmentsReducer
};

export default reducer;
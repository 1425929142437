import { useLocation } from "react-router-dom";
import Button from "../micro_components/Button";
import UnderlineTitle from "./UnderlineTitle";
import { calculatePriceOfPromotion } from "../functions/calculatePriceOfPromotion";
import { useState } from "react";
import { useEffect } from "react";

function ProductData({ setIsBooking, price, category, promotion }) {
  const location = useLocation();
  const [pricePromotion, setPricePromotion] = useState();

  useEffect(() => {
    if (
      promotion &&
      location.pathname !== "/product/relecture-cv-ou-lettre-de-motivation"
    ) {
      setPricePromotion(calculatePriceOfPromotion(price, category, promotion));
    } else if (
      promotion &&
      location.pathname === "/product/relecture-cv-ou-lettre-de-motivation"
    ) {
      setPricePromotion(
        calculatePriceOfPromotion(price * 2, category, promotion)
      );
    }
  }, [promotion, category, price, location.pathname]);

  return (
    <>
      <UnderlineTitle string={"A propos"} />
      <ul className="p-3 font-medium text-gray-900 border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white">
        <li className="w-full px-4 py-2 border-b border-gray-200 rounded-t-lg dark:border-gray-600 flex items-center">
          <p className="font-extrabold text-xl text-current-color-2 dark:text-current-color">
            {promotion ? (
              <>
                <span className="text-sm  text-green-600">
                  {promotion}% de réduction
                </span>
                <br />
                <span>{pricePromotion} € &nbsp;</span>
                <span className="font-normal text-black dark:text-white text-sm">
                  {category === "Coaching"
                    ? `${
                        location.pathname !==
                        "/product/relecture-cv-ou-lettre-de-motivation"
                          ? "/30 minutes"
                          : "la relecture"
                      }`
                    : ""}{" "}
                  &nbsp;
                </span>
                <br />
                <span className="line-through text-gray-600 font-normal text-sm">
                  {price !== "0.00"
                    ? `${
                        location.pathname !==
                          "/product/relecture-cv-ou-lettre-de-motivation" &&
                        category === "Coaching"
                          ? price
                          : price
                      } €`
                    : "Gratuit"}{" "}
                  &nbsp;
                  {category === "Coaching"
                    ? `${
                        location.pathname !==
                        "/product/relecture-cv-ou-lettre-de-motivation"
                          ? "/30 minutes"
                          : "la relecture"
                      }`
                    : ""}{" "}
                  &nbsp;
                </span>
              </>
            ) : (
              <>
                <span>
                  {price !== "0.00"
                    ? `${
                        location.pathname !==
                          "/product/relecture-cv-ou-lettre-de-motivation" &&
                        category === "Coaching"
                          ? price
                          : price
                      } €`
                    : "Gratuit"}
                  &nbsp;
                </span>
                <span className="font-normal text-black dark:text-white text-sm">
                  {category === "Coaching"
                    ? `${
                        location.pathname !==
                        "/product/relecture-cv-ou-lettre-de-motivation"
                          ? ""
                          : "la relecture"
                      }`
                    : ""}{" "}
                  &nbsp;
                </span>
                <br />
              </>
            )}
          </p>
        </li>
        {category === "Coaching" ? (
          <Button
            onClick={() => {
              setIsBooking(true);
            }}
            text={
              location.pathname ===
              "/product/relecture-cv-ou-lettre-de-motivation"
                ? "Demander ma relecture"
                : "Prendre rendez-vous"
            }
            iconClassName={
              location.pathname ===
              "/product/relecture-cv-ou-lettre-de-motivation"
                ? "fa-solid fa-book-open"
                : "fa-regular fa-calendar"
            }
            className="my-3 mx-auto"
          ></Button>
        ) : (
          <Button
            onClick={() => {
              setIsBooking(true);
            }}
            text="Je veux savoir"
            iconClassName="fa-regular fa-circle-up"
            className="my-3 mx-auto"
          ></Button>
        )}
      </ul>
    </>
  );
}
export default ProductData;

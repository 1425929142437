import { createReducer } from '@reduxjs/toolkit';
import { toast } from 'sonner';

import actionGetAllCoachings from '../../asyncActions/getAllCoachings';
import actionGetCurrentProduct from '../../asyncActions/getCurrentProduct';
import actionGetAllEbooks from '../../asyncActions/getAllEbooks';
import actionFreeProduct from '../../asyncActions/freeProduct';
import { useNavigate } from 'react-router-dom';
import actionGetAllSlugs from '../../asyncActions/getAllSlugs';

const initialState = {
    currentProduct: {
      'product': null,
      'appointment': null
    },
    allCoachings : [],
    allEbooks : [],
    isLoading : true,
    allSlugs: []
};




const productsReducer = createReducer(initialState, (builder) => {builder
  // Get all coachings in the DB
  .addCase(actionGetAllCoachings.pending, (state) => {
    state.isLoading = true;
  }) 
  .addCase(actionGetAllCoachings.fulfilled, (state, action) => {
    state.allCoachings = action.payload;
    state.isLoading = false;
  })
  .addCase(actionGetAllCoachings.rejected, (state, action) => {
    toast.error(action.payload);
    state.isLoading = false;
  })

  // Get all ebooks in the DB
  .addCase(actionGetAllEbooks.pending, (state) => {
    state.isLoading = true;
  }) 
  .addCase(actionGetAllEbooks.fulfilled, (state, action) => {
    state.allEbooks = action.payload
    state.isLoading = false;
  })
  .addCase(actionGetAllEbooks.rejected, (state, action) => {
    toast.error(action.payload.message);
    state.isLoading = false;
  })

  // Get product by current slug
  .addCase(actionGetCurrentProduct.pending, (state) => {
    state.isLoading = true;
  }) 
  .addCase(actionGetCurrentProduct.fulfilled, (state, action) => {
    state.currentProduct.product = action.payload;
    state.isLoading = false;
  })
  .addCase(actionGetCurrentProduct.rejected, (state, action) => {
    toast.error(action.payload.message);
    state.isLoading = false;
  })

  // Create a new order for free product
  .addCase(actionFreeProduct.pending, (state) => {
    state.isLoading = true;
  })
  .addCase(actionFreeProduct.fulfilled, (state, action) => {
    toast.success(action.payload);
    state.isLoading = false;
  })
  .addCase(actionFreeProduct.rejected, (state, action) => {
    
    toast.error(action.payload.message);
    state.isLoading = false;
  })
  
  // Get all slugs for redirect to 404
  .addCase(actionGetAllSlugs.pending, (state) => {
    state.isLoading = true;
  })
  .addCase(actionGetAllSlugs.fulfilled, (state, action) => {
    state.allSlugs = action.payload;
    state.isLoading = false;
  })
  .addCase(actionGetAllSlugs.rejected, (state, action) => {
    toast.error(action.payload.message);
    state.isLoading = false;
  })
});

export default productsReducer;
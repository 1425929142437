import Hero from "../components/Hero";
import SlideCards from "../components/SlideCards";
import TabsPresentation from "../components/TabsPresentation";
import ConfirmPayment from "../modal_components/ConfirmPayment";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import HomeCoachings from "../sections/HomeCoachings";

function Home() {
  const [modalHidden, setModalHidden] = useState(true);
  const location = useLocation();
  const allEbooks = useSelector((state) => state.products.allEbooks);

  useEffect(() => {
    if (location.search.includes("redirect_status")) {
      setModalHidden(false);
    }
  }, [location.search]);

  return (
    <div className="dark:bg-gray-900">
      <Hero />
      {!modalHidden && <ConfirmPayment setModalHidden={setModalHidden} />}
      <HomeCoachings />
      {/* <SlideCards
        productType={"Formation"}
        products={allCoachings}
        sectionName={"Les formations"}
      /> */}
      <TabsPresentation />
      <SlideCards
        productType={"Ebook"}
        products={allEbooks}
        sectionName={"Les Ebooks"}
      />
    </div>
  );
}

export default Home;

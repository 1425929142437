import React from "react";
import { useSelector } from "react-redux";
import UnderlineTitle from "../components/UnderlineTitle";
import Card from "../components/Card";

export default function HomeCoachings() {
  const allCoachings = useSelector((state) => state.products.allCoachings);
  return (
    <div>
      <UnderlineTitle string="Les coachings" className={"text-center"} />
      <div className="px-2 flex flex-col gap-4">
        {allCoachings.map((product) => (
          <Card
            title={product.title}
            productRate={product.rate}
            price={product.price}
            description={product.description}
            picture={product.picture}
            slug={product.slug}
            category={product.category}
            promotion={product.promotion}
          />
        ))}
      </div>
    </div>
  );
}

import { Toaster } from "sonner";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, redirect, useLocation } from "react-router-dom";
import { useEffect } from "react";

import Home from "./pages/Home";
import Product from "./pages/Product";
import NotFound from "./pages/NotFound";
import Faq from "./pages/Faq";
import Contact from "./pages/Contact";
import LegalNotice from "./pages/LegalNotice";
import TermOfSale from "./pages/TermOfSale";

import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import AboutUs from "./pages/AboutUs";
import actionGetAllSlugs from "./asyncActions/getAllSlugs";
import Alert from "./components/Alert";

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const allSlugs = useSelector((state) => state.products.allSlugs);

  useEffect(() => {
    dispatch(actionGetAllSlugs());
  }, [dispatch, location]);

  return (
    <div className="dark:bg-gray-900">
      <Toaster richColors />
      {process.env.REACT_APP_DEBUG === "true" && <Alert />}
      <Navbar></Navbar>
      <Routes>
        {allSlugs.length !== 0 &&
          location.pathname.substring(1, 8) === "product" &&
          allSlugs.includes(location.pathname.substring(9)) && (
            <Route path="/product/:slug" element={<Product />} />
          )}
        <Route path="/" element={<Home />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/mentions-legales" element={<LegalNotice />} />
        <Route path="/a-propos" element={<AboutUs />} />
        <Route path="/cgv" element={<TermOfSale />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer></Footer>
    </div>
  );
}

export default App;

import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../axios/axiosInstance';


/**
 * Asynchronous action to get all coachings in the DB
 */
const actionGetAllAppointments = createAsyncThunk(
  'getAllAppointments',
  async (_, thunkAPI) => {
    try {
      const result = await axiosInstance.get(`/appointments`);
      return result.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export default actionGetAllAppointments;
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import Card from "./Card";
import EbookCard from "./EbookCard";
import React, { useRef, useEffect, useState } from "react";
import UnderlineTitle from "./UnderlineTitle";

function SlideCards({ productType, sectionName, products }) {
  // const swipersRef = useRef(null);
  // const [heightSwiper, setHeightSwiper] = useState(0);

  // useEffect(() => {
  //   if (swipersRef.current) {
  //     setHeightSwiper(swipersRef.current.offsetHeight)
  //   }
  // }, [swipersRef])
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Nettoyage de l'écouteur d'événement lors du démontage du composant
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <section className="max-w-screen-xl m-auto my-10">
      <UnderlineTitle string={sectionName} className={"text-center"} />
      {products.length > 3 || screenWidth <= 728 ? (
        <Swiper
          // ref={swipersRef}
          modules={[Navigation]}
          navigation
          loop={true}
          slidesPerView={1}
          centeredSlides
          centeredSlidesBounds
          breakpoints={{
            728: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
        >
          {products &&
            products.map((product) => (
              <SwiperSlide key={product.id}>
                {productType === "Ebook" ? (
                  <EbookCard
                    slug={product.slug}
                    picture={product.picture}
                  ></EbookCard>
                ) : (
                  <Card
                    title={product.title}
                    productRate={product.rate}
                    price={product.price}
                    picture={product.picture}
                    slug={product.slug}
                    category={product.category}
                    promotion={product.promotion}
                  ></Card>
                )}
              </SwiperSlide>
            ))}
        </Swiper>
      ) : (
        <div className="flex items-center">
          {products &&
            products.map((product, index) =>
              productType === "Ebook" ? (
                <EbookCard
                  key={index}
                  slug={product.slug}
                  picture={product.picture}
                ></EbookCard>
              ) : (
                <Card
                  key={index}
                  title={product.title}
                  productRate={product.rate}
                  price={product.price}
                  picture={product.picture}
                  slug={product.slug}
                  category={product.category}
                  promotion={product.promotion}
                ></Card>
              )
            )}
        </div>
      )}
    </section>
  );
}

export default SlideCards;

import {  createReducer } from '@reduxjs/toolkit';
import actionGetQuestions from '../../asyncActions/getQuestions';
import { toast } from 'sonner';

const initialState = {
  isLoading: false,
  questions: []
};


const questionsReducer = createReducer(initialState, (builder) => {builder
  // Get all questions in the DB
  .addCase(actionGetQuestions.pending, (state) => {
    state.isLoading = true
  }) 
  .addCase(actionGetQuestions.fulfilled, (state, action) => {
    state.questions = action.payload;
    state.isLoading = false;
  }) 
  .addCase(actionGetQuestions.rejected, (state, action) => {
    toast.error(action.payload);
    state.isLoading = false;
  }) 
});

export default questionsReducer;
import Include from "./Include";
import UnderlineTitle from "./UnderlineTitle";

function ProductIncludes({includes}) {
  return (
    <section className="my-10" id="inclus">
      <UnderlineTitle string={"Ce que tu trouveras"}></UnderlineTitle>
      <div className="grid grid-cols-1 gap-2 md:grid-cols-2 ">
        {Array.isArray(includes) && includes.map((include, index) => 
          <Include key={index} icon={include.icon} title={include.title} description={include.description}></Include>
        )}
      </div>
    </section>


  );
}

export default ProductIncludes;

import { useState } from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import Calendar from "../components/Calendar";
import ProductIncludes from "../components/ProductIncludes";
import ProductHeader from "../components/ProductHeader";
import ProductCurrentProblems from "../components/ProductCurrentProblems";
import ProductDescription from "../components/ProductDescription";
import ProductPlus from "../components/ProductPlus";
import ProductData from "../components/ProductData";
import ProductSuggestions from "../components/ProductSuggestions";
import ProductTabs from "../components/ProductTabs";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import actionGetCurrentProduct from "../asyncActions/getCurrentProduct";
import Loader from "../micro_components/Loader";
import Datepicker from "tailwind-datepicker-react";
import UnderlineTitle from "../components/UnderlineTitle";

const options = {
  title: "",
  autoHide: true,
  todayBtn: false,
  clearBtn: true,
  clearBtnText: "Clear",
  maxDate: new Date("2030-01-01"),
  minDate: new Date(),
  theme: {
    background: "bg-white dark:bg-gray-800",
    todayBtn: "",
    clearBtn: "",
    icons: "",
    text: "",
    disabledText: "bg-gray-500",
    input: "",
    inputIcon: "",
    selected: "",
  },
  icons: {
    // () => ReactElement | JSX.Element
    prev: () => <span>Previous</span>,
    next: () => <span>Next</span>,
  },
  datepickerClassNames: "",
  defaultDate: new Date(),
  language: "en",
  disabledDates: [],
  weekDays: ["Lu", "Tu", "We", "Th", "Fr", "Sa", "Su"],
  inputNameProp: "date",
  inputIdProp: "date",
  inputPlaceholderProp: "Select Date",
  inputDateFormatProp: {
    day: "numeric",
    month: "long",
    year: "numeric",
  },
};

function Product() {
  const [isBooking, setIsBooking] = useState(false);
  const dispatch = useDispatch();
  const { slug } = useParams();
  const product = useSelector(
    (state) => state.products.currentProduct.product
  ) || { category: "" };
  const isLoading = useSelector((state) => state.products.isLoading);
  const allCoachings = useSelector((state) => state.products.allCoachings);
  const allEbooks = useSelector((state) => state.products.allEbooks);

  useEffect(() => {
    dispatch(actionGetCurrentProduct({ slug }));
  }, [dispatch, slug]);

  const tabsRoutes = {
    "#description": "Description",
    "#problemes": "Problèmes",
    "#inclus": "Inclus",
    "#apport": "Apport",
  };
  let suggestions;
  if (product.category && product.category.name === "Coaching") {
    suggestions = allEbooks;
  } else if (product.category && product.category.name === "Ebook") {
    suggestions = allEbooks.filter((ebook) => ebook.id !== product.id);
  }

  const [show, setShow] = useState(false);
  const handleChange = (selectedDate) => {};
  const handleClose = (state) => {
    setShow(state);
  };
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <ProductHeader
            title={product.title}
            slogan={product.slogan}
          ></ProductHeader>
          <Breadcrumbs
            category={product.category.name}
            currentPage={product.title}
          ></Breadcrumbs>
          <div className="block dark:bg-gray-900 max-w-screen-xl mx-auto lg:flex ">
            <div className="p-3 w-full lg:w-3/4">
              <img
                className="w-full rounded-lg max-h-[500px] object-contain mt-6"
                src={product.picture}
                alt={product.title}
              ></img>
              {/* <ProductTabs tabsRoutes={tabsRoutes} ></ProductTabs> */}
              <ProductDescription
                description={product.description}
              ></ProductDescription>
              <ProductCurrentProblems
                currentProblems={product.currentProblems}
              ></ProductCurrentProblems>
              <ProductIncludes includes={product.includes}></ProductIncludes>
              <ProductPlus gains={product.gains}></ProductPlus>
              {product.id === 4 && (
                <div className="mt-10">
                  <UnderlineTitle string="Ne laisse pas passer cette oppurtunité !" />
                  <p className="dark:text-white">
                    La rémunération que tu reçois doit refléter le véritable
                    impact que tu as sur ton entreprise. Avec mon coaching, tu
                    seras prêt à défendre efficacement ta valeur et à obtenir le
                    salaire que tu mérites. <br />
                    <br />
                    <span className="text-current-color-2 dark:text-current-color font-bold">
                      Réserve ta consultation dès aujourd'hui{" "}
                    </span>{" "}
                    et commence à construire le futur financier que tu
                    souhaites.
                  </p>
                </div>
              )}
            </div>
            <aside className="lg:w-1/4 p-3">
              {!isBooking ? (
                <ProductData
                  price={product.price}
                  category={product.category.name}
                  setIsBooking={setIsBooking}
                  promotion={product.promotion}
                ></ProductData>
              ) : (
                <Calendar setIsBooking={setIsBooking}></Calendar>
              )}

              <ProductSuggestions
                suggestions={suggestions}
              ></ProductSuggestions>
              {/* <Datepicker options={options} onChange={handleChange} show={true} setShow={handleClose} /> */}
            </aside>
          </div>
          {/* <ProductReviews nbOfReviews={product.numberOfReviews} reviews={product.reviews} productRate={product.rate}></ProductReviews> */}
        </>
      )}
    </>
  );
}

export default Product;

function UnderlineTitle({string, className}) {
        const lengthString = string.length;
        let underlineString;
        let firstString;
        let lastString;
        if(lengthString%3 === 0){
            const divisionLength = lengthString/3;
            firstString = string.substring(0, divisionLength)
            underlineString = string.substring(divisionLength, divisionLength + divisionLength);
            lastString = string.substring(divisionLength +  divisionLength, lengthString);
        } else {
            const divisionLength = Math.floor(lengthString/3);
            const middleStringLength = lengthString - (divisionLength * 2);
            firstString = string.substring(0, divisionLength)
            underlineString = string.substring(divisionLength, divisionLength + middleStringLength);
            lastString = string.substring(divisionLength + middleStringLength, lengthString);
        }
  return (
    <h3 className={`mb-4 text-2xl font-extrabold leading-none tracking-tight text-gray-900 md:text-3xl lg:text-3xl dark:text-white ${className}`}>
    {firstString}
    <span className="underline underline-offset-3 decoration-4 decoration-current-color-2 dark:decoration-current-color-2">{underlineString}</span>
    {lastString}</h3>
  );
}

export default UnderlineTitle;

import { Link } from "react-router-dom";
import UnderlineTitle from "./UnderlineTitle";


function ProductSuggestions({suggestions}) {
  return (
    <section className="my-6">
          <UnderlineTitle string={"Suggestions"}></UnderlineTitle>
          {suggestions && suggestions.map((suggestion) => 
            <Link key={suggestion.id} to={`/product/${suggestion.slug}`} className="flex items-center bg-white border border-gray-200 mb-3 h-24 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
              <img className="object-cover w-1/4 h-full rounded-l-lg md:rounded-none md:rounded-s-lg" src={suggestion.picture} alt="" />
              <h5 className="mb-2 px-4 text-base font-bold tracking-tight text-gray-900 dark:text-white">{suggestion.title}</h5>
            </Link>
          )}
          
        </section>

    
    
  );
}

export default ProductSuggestions;

// import { splitTitleForHightlightRandomWord } from "../functions/splitTitleForHighlightRandomWord";


function ProductHeader({title, slogan}) {
  // const hightlightTitle = splitTitleForHightlightRandomWord(title);

  return (
    <section className="bg-bottom h-80  bg-no-repeat bg-cover bg-[url('https://firebasestorage.googleapis.com/v0/b/boostacarriere-2679a.appspot.com/o/files%2Ftop-product.jpg?alt=media&token=7e979f84-81f9-4f6c-a58e-35169396083a')] bg-gray-700 bg-blend-multiply">
        <div className="px-4 mx-auto max-w-screen-xl py-10 text-center xl:py-24">      
            <h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight md:text-5xl lg:text-6xl text-white">{title}</h1>
            <p className="text-lg font-normal text-white lg:text-xl">{slogan}</p>
        </div>
    </section>
  );
}

export default ProductHeader;

import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../axios/axiosInstance';


/**
 * Asynchronous action to store a new newsletter register in the DB
 * @param {string} email: user's email
 */
const actionPostNewsletterRegister = createAsyncThunk(
  'postNewsletterRegister',
  async ({ email }, thunkAPI) => {
    try {
      const result = await axiosInstance.post(`/newsletter-registers`, {email});
      return result.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export default actionPostNewsletterRegister;
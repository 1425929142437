import UnderlineTitle from "./UnderlineTitle";


function ProductDescription({description}) {
  return (
    <section className="my-8 p-5 lg:p-0" id="description">
      <UnderlineTitle string={"Description"}></UnderlineTitle>
      <p className="text-justify text-gray-500 text-base dark:text-gray-200">{description}</p>
    </section>
  );
}

export default ProductDescription;

function TermOfSale() {
  return (
    <div className="max-w-screen-xl mx-auto">
      <h2 className="text-center text-3xl font-bold mb-10">Conditions générales de ventes</h2> 
      <h3 className="text-lg font-bold uppercase mt-5">Parties du contrat</h3>
      <p>Les présentes conditions générales de vente ont pour objet de régir le contrat conclu entre Boostacarriere nommée « Client » et toute personne morale ou physique ayant requis les services de Boostacarriere nommée « Le client ». Le terme « Tiers » désigne toute personne morale ou physique non désignée précédemment. Boostacarriere et le client sont nommés conjointement « les parties ».</p>
      <h3 className="text-lg font-bold uppercase mt-5">Article 1 - Généralités</h3>
      <p>Le client faisant appel aux services de Boostacarriere reconnaît explicitement à travers une option lors du cheminement de paiement qu'il a pris connaissance et accepté sans réserve les présentes Conditions Générales de Ventes. Boostacarriere se réserve le droit de modifier ses conditions générales de vente, ses prestations et ses tarifs à tout moment et sans préavis. Ces modifications n'auront aucune incidence sur les commandes antérieures et en cours.</p>
      <h3 className="text-lg font-bold uppercase mt-5">Article 2 - Engagement des parties</h3>
      <p>Chacune des parties s'engage à collaborer activement pour le bon déroulement du contrat et à communiquer pour garantir l'avancée du projet et l'aboutissement de la mission. </p>
      <ul className="list-disc ml-10">
        <li>Le client </li>
        <li>Boostacarriere</li>
      </ul>
    </div>
    
  );
}

export default TermOfSale;

import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../axios/axiosInstance";

/**
 * Asynchronous action to get all ebooks in the DB
 */
const actionGetAllSlugs = createAsyncThunk(
  "getAllSlugs",
  async (_, thunkAPI) => {
    try {
      const result = await axiosInstance.get(`/all-slugs`);
      return result.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export default actionGetAllSlugs;

import { useDispatch, useSelector } from "react-redux";
import Button from "../micro_components/Button";
import InputIcon from "../micro_components/InputIcon";
import Mail from "../micro_components/svg/Mail";
import { useEffect, useState } from "react";
import actionPostNewsletterRegister from "../asyncActions/newsletterRegistersStore";

function Hero() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const isCreated = useSelector((state) => state.newsletter.isCreated);
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(actionPostNewsletterRegister({ email }));
  };
  useEffect(() => {
    if (isCreated) {
      setEmail("");
    }
  }, [isCreated]);

  return (
    <section className="bg-white dark:bg-gray-900 ">
      <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
        <div className="mr-auto place-self-center lg:col-span-7">
          <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl text-current-color-2 dark:text-white">
            Construis ta carrière, Nourris ton potentiel
          </h1>
          <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
            De la préparation de{" "}
            <span className="text-current-color-2 font-bold">
              ta demande d'augmentation
            </span>{" "}
            à la maîtrise des techniques de négociation, je t'accompagne{" "}
            <span className="text-current-color-2 font-bold">
              avec des stratégies éprouvées
            </span>{" "}
            pour obtenir le salaire que tu mérites et transformer ta carrière.
          </p>
          <div className="flex flex-col md:flex-row  md:items-center gap-2">
            <form
              className="w-full min-[400px]:flex-row flex flex-col gap-2"
              onSubmit={handleSubmit}
            >
              <InputIcon
                iconSvg={<Mail />}
                placeholder={"Entre ton adresse mail"}
                className={"font-medium md:w-3/4"}
                id={"input-group-1"}
                onChange={setEmail}
                value={email}
                type={"email"}
              />
              <Button
                type="submit"
                text={"Reste informé"}
                hover
                className="w-fit"
              ></Button>
            </form>
          </div>
        </div>
        <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
          <img
            src="https://www.lexegalis.com/wp-content/uploads/2022/12/PNG-Conseil-RH-1536x864.png"
            alt="mockup"
          />
        </div>
      </div>
    </section>
  );
}

export default Hero;

import { useEffect, useState } from "react";
import Button from "../micro_components/Button";
import { useNavigate } from "react-router-dom";

function ConfirmPayment({setModalHidden}) {
  const navigate = useNavigate();
  const [animFlip, setAnimFlip] = useState(true);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setAnimFlip(false);
    }, 2000);

    // Retour de fonction de nettoyage pour annuler le setTimeout
    return () => clearTimeout(timeoutId);
  }, []); // Le tableau vide en tant que deuxième argument signifie que ce code ne sera exécuté qu'une seule fois après le premier rendu

  return (
<div  tabIndex="-1" className={`overflow-y-auto bg-gray-600 bg-opacity-70 overflow-x-hidden fixed top-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-full`}>
    <div className="relative p-4 w-full">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 top-12 left-1/2 -translate-x-1/2 md:w-1/2">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={() => {setModalHidden(true); navigate('/')}}>
                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                    <span className="sr-only">Fermer la modal</span>
                </button>
            </div>
            <div className="p-4 md:p-5 space-y-4">
                <i className={`text-green-500 text-6xl block w-fit m-auto fa-solid fa-circle-check fa-2xl" ${animFlip && 'fa-flip'}`}></i>
                <p className="text-xl text-center font-bold leading-relaxed text-gray-700 dark:text-gray-400">
                    Paiement effectué
                </p>
                <p className="text-base text-center leading-relaxed text-gray-500 dark:text-gray-400">
                    Ton paiement a bien été effectué. Tu vas recevoir un mail contenant la confirmation de ce paiement et si besoin, le produit que tu as commandé. 
                </p>
            </div>
            <div className="flex items-center p-4 gap-2 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                <Button text="Je trouve un autre produit" hover onClick={() => setModalHidden(true)}></Button>
                <Button text="J'ai une question" reverse onClick={() => navigate("faq")}></Button>
            </div>
        </div>
    </div>
</div>



  );
}

export default ConfirmPayment;

import { Link } from "react-router-dom";

function Breadcrumbs({category, currentPage}) {
  return (
  <nav className="flex px-5 py-3 text-white  bg-current-color-2 dark:bg-gray-800" aria-label="Breadcrumb">
    <ol className="flex items-center space-x-1 md:space-x-2 rtl:space-x-revers m-auto">
      <li className="inline-flex items-center">
        <Link to="/" className="inline-flex items-center text-sm font-medium text-white hover:text-current-color dark:text-white dark:hover:text-current-color" >
          <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"/>
          </svg>
          <span className="ms-1 text-xs sm:text-sm font-medium text-white md:ms-2 dark:text-white">Accueil</span>
        </Link>
      </li>
      <li>
        <div className="flex items-center">
          <svg className="rtl:rotate-180  w-3 h-3 mx-1 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
          </svg>
          <span className="ms-1 text-xs sm:text-sm font-medium text-white md:ms-2 dark:text-white">{category}</span>
        </div>
      </li>
      <li>
        <div className="flex items-center">
          <svg className="rtl:rotate-180  w-3 h-3 mx-1 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
          </svg>
          <span className="ms-1 text-xs sm:text-sm font-medium text-white md:ms-2 dark:text-white">{currentPage}</span>
        </div>
      </li>
    </ol>
  </nav>
  );
}

export default Breadcrumbs;

import { NavLink } from "react-router-dom";
import Logo from "../assets/img/logo.png"

function LogoName({imgSize, className=''}) {
  return (
    <NavLink to="/" className={`flex items-center space-x-3 rtl:space-x-reverse ${className}`}>
          <img src={Logo} className={`h-${imgSize}`} alt="Logo Boostacarriere" />
          <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white text-current-color-2">Boostacarriere</span>
    </NavLink>


  );
}

export default LogoName;

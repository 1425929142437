import Button from "../micro_components/Button";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import InputIcon from "../micro_components/InputIcon";
import Mail from "../micro_components/svg/Mail";
import actionPostNewsletterRegister from "../asyncActions/newsletterRegistersStore";

function AboutUs() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(actionPostNewsletterRegister({ email }));
  };
  return (
    <div>
      <div className="md:flex mt-10 max-w-screen-xl mx-auto">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/boostacarriere-2679a.appspot.com/o/files%2FIMG_0622.jpg?alt=media&token=0cd5f6df-f0e1-4fb4-af87-e612dd969c78"
          alt="Laurine"
          className="w-3/4 sm:w-1/2 m-auto md:w-1/3 rounded-3xl"
        ></img>
        <div className="md:ml-10 mt-5 p-5">
          <h2 className="text-center md:text-left text-3xl font-bold text-current-color-2 dark:text-current-color">
            Qui suis-je ?
          </h2>
          <p className="text-justify mt-3 dark:text-white">
            Je m'appelle Laurine et ça fait 5 ans que je suis plongée et
            passionnée par le monde des ressources humaines. Au fil de ces
            années, j'ai accumulé une expérience solide dans ce domaine en
            constante évolution.{" "}
          </p>
          <p className="mt-3 dark:text-white">
            J'ai eu l'occasion d'aider de nombreuses entreprises à gérer leurs
            ressources humaines, en mettant un point d'honneur sur le
            développement du potentiel humain et de leurs carrières.{" "}
          </p>
        </div>
      </div>
      <div className="mt-10 bg-current-color-2 w-full py-6">
        <h2 className="text-3xl font-bold text-white text-center">
          Mes études et parcours professionnel
        </h2>
        <ol className="px-4 mt-10 min-[850px]:flex block max-w-screen-xl md:mx-auto">
          <li className="relative mb-6 md:mb-0">
            <div className="flex items-center justify-center mt-5">
              <div className="z-10 flex items-center justify-center w-6 h-6 bg-current-color rounded-full ring-current-color ring-8  shrink-0">
                <i className="fa-solid fa-graduation-cap text-current-color-2"></i>
              </div>
              <div className="hidden min-[850px]:flex w-full bg-current-color h-0.5"></div>
            </div>
            <div className="mt-3 min-[850px]:pe-8">
              <h3 className="text-lg text-center min-[850px]:text-left font-semibold text-white">
                BTS SP3S
              </h3>
              <time className="block mb-2 text-sm font-normal text-center min-[850px]:text-left leading-none text-gray-200 ">
                School
              </time>
              <p className="text-base font-normal text-center min-[850px]:text-left text-gray-300">
                En tant qu'assistante des services généraux en alternance au
                sein d'un institut de jeunes sourds
              </p>
            </div>
          </li>
          <li className="relative mb-6 min-[850px]:mb-0">
            <div className="flex items-center justify-center mt-5">
              <div className="z-10 flex items-center justify-center w-6 h-6 bg-current-color rounded-full ring-current-color ring-8  shrink-0">
                <i className="fa-solid fa-graduation-cap text-current-color-2"></i>
              </div>
              <div className="hidden min-[850px]:flex w-full bg-current-color h-0.5"></div>
            </div>
            <div className="mt-3 min-[850px]:pe-8">
              <h3 className="text-lg text-center min-[850px]:text-left font-semibold text-white">
                Licence Ressources Humaines
              </h3>
              <time className="block mb-2 text-sm font-normal text-center min-[850px]:text-left leading-none text-gray-200 ">
                La Sorbonne
              </time>
              <p className="text-base font-normal text-center min-[850px]:text-left text-gray-300">
                En tant que gestionnaire de paie en alternance au sein d'une
                mutuelle nationale
              </p>
            </div>
          </li>
          <li className="relative mb-6 min-[850px]:mb-0">
            <div className="flex items-center justify-center mt-5">
              <div className="z-10 flex items-center justify-center w-6 h-6 bg-current-color rounded-full ring-current-color ring-8  shrink-0">
                <i className="fa-solid fa-graduation-cap text-current-color-2"></i>
              </div>
              <div className="hidden min-[850px]:flex w-full bg-current-color h-0.5"></div>
            </div>
            <div className="mt-3 min-[850px]:pe-8">
              <h3 className="text-lg text-center min-[850px]:text-left font-semibold text-white">
                Mastère Développement des Ressources Humaines
              </h3>
              <time className="block mb-2 text-sm font-normal text-center min-[850px]:text-left leading-none text-gray-200 ">
                Sup des RH
              </time>
              <p className="text-base font-normal text-center min-[850px]:text-left text-gray-300">
                En tant que gestionnaire des ressources humaines en alternance
                au sein d'un laboratoire pharmaceutique international
              </p>
            </div>
          </li>
          <li className="relative mb-6 min-[850px]:mb-0">
            <div className="flex items-center justify-center mt-5">
              <div className="z-10 flex items-center justify-center w-6 h-6 bg-current-color rounded-full ring-current-color ring-8  shrink-0">
                <i className="fa-solid fa-graduation-cap text-current-color-2"></i>
              </div>
              <div className="hidden min-[850px]:flex w-full bg-current-color h-0.5"></div>
            </div>
            <div className="mt-3 min-[850px]:pe-8">
              <h3 className="text-lg text-center min-[850px]:text-left font-semibold text-white">
                CDI
              </h3>
              <time className="block mb-2 text-sm font-normal text-center min-[850px]:text-left leading-none text-gray-200 ">
                Depuis 2022
              </time>
              <p className="text-base font-normal text-center min-[850px]:text-left text-gray-300">
                En tant que gestionnaire des ressources humaines au sein d'un
                groupe immobilier
              </p>
            </div>
          </li>
        </ol>
      </div>
      <div className="mt-10 max-w-screen-xl mx-auto">
        <h2 className="text-center text-current-color-2 font-bold text-3xl dark:text-current-color">
          Mon savoir-être
        </h2>
        <div className="flex flex-col md:flex-row items-center justify-between mt-10 gap-6 p-4">
          <div className="flex md:block items-center max-w-sm p-2 rounded-lg border border-current-color-2 dark:border-current-color overflow-hidden w-full md:w-1/4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              className="w-8 h-8 md:m-auto fill-current-color-2 dark:fill-current-color md:mt-4"
            >
              <path d="M336 352c97.2 0 176-78.8 176-176S433.2 0 336 0S160 78.8 160 176c0 18.7 2.9 36.8 8.3 53.7L7 391c-4.5 4.5-7 10.6-7 17v80c0 13.3 10.7 24 24 24h80c13.3 0 24-10.7 24-24V448h40c13.3 0 24-10.7 24-24V384h40c6.4 0 12.5-2.5 17-7l33.3-33.3c16.9 5.4 35 8.3 53.7 8.3zM376 96a40 40 0 1 1 0 80 40 40 0 1 1 0-80z" />
            </svg>
            <div className="px-6 py-4 font-bold md:text-sm xl:text-lg  md:mb-2 text-current-color-2 dark:text-current-color text-center">
              Sens de la confidentialité
            </div>
          </div>
          <div className="flex md:block items-center max-w-sm p-2 rounded-lg border border-current-color-2 dark:border-current-color overflow-hidden w-full md:w-1/4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              className="w-8 h-8 md:m-auto fill-current-color-2 dark:fill-current-color md:mt-4"
            >
              <path d="M398.3 3.4c-15.8-7.9-35-1.5-42.9 14.3c-7.9 15.8-1.5 34.9 14.2 42.9l.4 .2c.4 .2 1.1 .6 2.1 1.2c2 1.2 5 3 8.7 5.6c7.5 5.2 17.6 13.2 27.7 24.2C428.5 113.4 448 146 448 192c0 17.7 14.3 32 32 32s32-14.3 32-32c0-66-28.5-113.4-56.5-143.7C441.6 33.2 427.7 22.2 417.3 15c-5.3-3.7-9.7-6.4-13-8.3c-1.6-1-3-1.7-4-2.2c-.5-.3-.9-.5-1.2-.7l-.4-.2-.2-.1-.1 0 0 0c0 0 0 0-14.3 28.6L398.3 3.4zM128.7 227.5c6.2-56 53.7-99.5 111.3-99.5c61.9 0 112 50.1 112 112c0 29.3-11.2 55.9-29.6 75.9c-17 18.4-34.4 45.1-34.4 78V400c0 26.5-21.5 48-48 48c-17.7 0-32 14.3-32 32s14.3 32 32 32c61.9 0 112-50.1 112-112v-6.1c0-9.8 5.4-21.7 17.4-34.7C398.3 327.9 416 286 416 240c0-97.2-78.8-176-176-176C149.4 64 74.8 132.5 65.1 220.5c-1.9 17.6 10.7 33.4 28.3 35.3s33.4-10.7 35.3-28.3zM32 512a32 32 0 1 0 0-64 32 32 0 1 0 0 64zM192 352a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3l64 64c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-64-64c-12.5-12.5-32.8-12.5-45.3 0zM208 240c0-17.7 14.3-32 32-32s32 14.3 32 32c0 13.3 10.7 24 24 24s24-10.7 24-24c0-44.2-35.8-80-80-80s-80 35.8-80 80c0 13.3 10.7 24 24 24s24-10.7 24-24z" />
            </svg>
            <div className="px-6 py-4 font-bold md:text-sm xl:text-lg  md:mb-2 text-current-color-2 dark:text-current-color text-center">
              Écoute active
            </div>
          </div>
          <div className="flex md:block items-center max-w-sm p-2 rounded-lg border border-current-color-2 dark:border-current-color overflow-hidden w-full md:w-1/4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512"
              className="w-8 h-8 md:m-auto fill-current-color-2 dark:fill-current-color md:mt-4"
            >
              <path d="M80 104c0-22.1-17.9-40-40-40S0 81.9 0 104v56 64V325.5c0 25.5 10.1 49.9 28.1 67.9L128 493.3c12 12 28.3 18.7 45.3 18.7H240c26.5 0 48-21.5 48-48V385.1c0-29.7-11.8-58.2-32.8-79.2l-25.3-25.3 0 0-15.2-15.2-32-32c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l32 32 15.2 15.2c11 11 9.2 29.2-3.7 37.8c-9.7 6.5-22.7 5.2-31-3.1L98.7 309.5c-12-12-18.7-28.3-18.7-45.3V224 144 104zm480 0v40 80 40.2c0 17-6.7 33.3-18.7 45.3l-51.1 51.1c-8.3 8.3-21.3 9.6-31 3.1c-12.9-8.6-14.7-26.9-3.7-37.8l15.2-15.2 32-32c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-32 32-15.2 15.2 0 0-25.3 25.3c-21 21-32.8 49.5-32.8 79.2V464c0 26.5 21.5 48 48 48h66.7c17 0 33.3-6.7 45.3-18.7l99.9-99.9c18-18 28.1-42.4 28.1-67.9V224 160 104c0-22.1-17.9-40-40-40s-40 17.9-40 40z" />
            </svg>
            <div className="px-6 py-4 font-bold md:text-sm xl:text-lg  md:mb-2 text-current-color-2 dark:text-current-color text-center">
              Empathie
            </div>
          </div>
          <div className="flex md:block items-center max-w-sm p-2 rounded-lg border border-current-color-2 dark:border-current-color overflow-hidden w-full md:w-1/4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              className="w-8 h-8 md:m-auto fill-current-color-2 dark:fill-current-color md:mt-4"
            >
              <path d="M40 48C26.7 48 16 58.7 16 72v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V72c0-13.3-10.7-24-24-24H40zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM16 232v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V232c0-13.3-10.7-24-24-24H40c-13.3 0-24 10.7-24 24zM40 368c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V392c0-13.3-10.7-24-24-24H40z" />
            </svg>
            <div className="px-6 py-4 font-bold md:text-sm xl:text-lg  md:mb-2 text-current-color-2 dark:text-current-color text-center">
              Rigueur
            </div>
          </div>
        </div>
      </div>
      <div className="mt-10 max-w-screen-xl mx-auto p-5">
        <h2 className="text-center text-current-color-2 font-bold text-3xl dark:text-current-color">
          Prêt(e) à te dépasser ?
        </h2>
        <p className="text-center my-4 dark:text-white">
          {" "}
          Aujourd'hui, j'ai décidé de partager avec toi mes connaissances et mon
          expertise à travers ce site. Que tu sois un pro des RH en quête de
          conseils et d'outils pratiques, ou simplement quelqu'un qui souhaite
          développer ses compétences et son épanouissement professionnel, tu
          trouveras ici des ressources adaptées à tes besoins.
        </p>
        <p className="text-center my-4 dark:text-white">
          Mon but est de t'accompagner dans ton parcours professionnel, en te
          proposant des coachings personnalisés et des contenus pertinents pour
          relever les défis du monde du travail d'aujourd'hui.
        </p>
        <p className="text-center my-4 dark:text-white">
          N'hésite pas à me contacter si tu as des questions ou besoin
          d'informations supplémentaires. J'ai hâte de pouvoir contribuer à ton
          succès professionnel !
        </p>
        <Button
          text="C'est parti !"
          className="m-auto"
          onClick={() => navigate("/")}
          hover={true}
        />
      </div>
      <div className="mt-10 max-w-screen-xl mx-auto">
        <h2 className="text-center text-current-color-2 font-bold text-3xl dark:text-current-color">
          Ma newsletter
        </h2>
        <p className="my-4 text-center">
          Pour recevoir régulièrement tous les conseils pour trouver un travail,
          améliorer son CV, augmenter son salaire et du contenu exclusif.
        </p>
        <form
          className="w-full px-4 min-[400px]:flex-row flex flex-col gap-2 justify-center"
          onSubmit={handleSubmit}
        >
          <InputIcon
            iconSvg={<Mail />}
            placeholder={"Entre ton adresse mail"}
            className={"font-medium min-[400px]:w-1/2 md:w-1/3"}
            id={"input-group-1"}
            onChange={setEmail}
            value={email}
            type={"email"}
          />
          <Button
            type="submit"
            text={"Reste informés"}
            hover
            className="w-fit m-auto min-[400px]:m-0"
          ></Button>
        </form>
      </div>
    </div>
  );
}

export default AboutUs;

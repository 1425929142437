import { createReducer } from '@reduxjs/toolkit';
import { toast } from 'sonner';
import actionContactForm from '../../asyncActions/contactForm';


const initialState = {
    isSending: false,
    isLoading: false
};




const contactReducer = createReducer(initialState, (builder) => {
  builder.addCase(actionContactForm.pending, (state) => {
    state.isLoading = true;
  })
  .addCase(actionContactForm.fulfilled, (state) => {
    toast.success("Ton message a bien été envoyé");
    state.isSending = true;
    state.isLoading = false;
}) 
  .addCase(actionContactForm.rejected, (_, action) => {
    toast.error(action.payload);
  })
});

export default contactReducer;
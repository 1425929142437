import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../axios/axiosInstance";

/**
 * Asynchronous action for create a new payment intent to the Stripe API
 * @param {datetime} appointment: Date and Time of Appointment. Format : yyyy-mm-dd hh:mm:ss
 * @param {string} name: User's name
 * @param {string} email: User's email
 * @param {number} productId: Current product's id
 */
const actionPaymentIntent = createAsyncThunk(
  "payment_intent",
  async (
    { appointment, duration, name, email, productId, termsAccepted },
    thunkAPI
  ) => {
    try {
      const result = await axiosInstance.post(`/payment-intend`, {
        appointment,
        duration,
        name,
        email,
        productId,
        termsAccepted,
      });
      return result.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export default actionPaymentIntent;

import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "../micro_components/Button";
import { useState } from "react";
import { useEffect } from "react";
import { calculatePriceOfPromotion } from "../functions/calculatePriceOfPromotion";
import { cutString } from "../functions/cutString";

/**
 * Card component for product
 * @param {string} title: product's title
 * @param {number} productRate: product's rate
 * @param {number} price: product's price
 * @param {string} picture: product's picture
 * @param {string} slug: product's slug
 */
function Card({
  title,
  productRate,
  price,
  picture,
  slug,
  promotion,
  category,
  description,
}) {
  const navigate = useNavigate();

  const [pricePromotion, setPricePromotion] = useState();
  const shortDescription = cutString(description, 250);

  useEffect(() => {
    if (promotion && slug !== "relecture-cv-ou-lettre-de-motivation") {
      setPricePromotion(calculatePriceOfPromotion(price, category, promotion));
    } else if (promotion && slug === "relecture-cv-ou-lettre-de-motivation") {
      setPricePromotion(
        calculatePriceOfPromotion(price * 2, category, promotion)
      );
    }
  }, [promotion, category, price, slug]);
  return (
    <div className="md:flex justify-between items-center w-full max-w-3xl m-auto md:h-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
      <img
        className="md:p-8 rounded-t-lg h-48 mx-auto md:mx-0 md:h-full md:w-1/3 object-cover"
        src={picture}
        alt="product"
      />
      <div className="md:w-2/3 py-8 px-4">
        <div className="md:flex items-center justify-between ">
          <h3 className="font-semibold text-lg dark:text-white">{title}</h3>
          <p className="font-bold text-lg dark:text-white">{price}€</p>
        </div>
        <p className="text-sm mt-4 dark:text-white">{shortDescription}</p>
        <div className="flex justify-end mt-2">
          <Button
            text={"Voir plus"}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/product/${slug}`);
            }}
          ></Button>
        </div>
      </div>
    </div>
    // <div className="flex justify-between w-full max-w-3xl m-auto h-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
    //   <Link to={`/product/${slug}`}>
    //     <img
    //       className="p-8 rounded-t-lg h-56 w-full object-cover"
    //       src={picture}
    //       alt="product"
    //     />
    //   </Link>
    //   <Link to={`/product/${slug}`} className="px-5 pb-5">
    //     <h5 className="text-xl font-semibold tracking-tight text-gray-900 dark:text-white">
    //       {title}
    //     </h5>
    //   </Link>
    //   <div className="px-5 pb-5">
    //     {productRate && (
    //       <div className="flex items-center mt-2.5 mb-5">
    //         <span className="bg-blue-100 text-blue-800 text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ms-3">
    //           {productRate}
    //         </span>
    //       </div>
    //     )}

    //     <div className="flex items-center justify-between">
    //       {promotion ? (
    //         <div className="flex items-center">
    //           <span className="text-xl font-bold dark:text-white">
    //             {pricePromotion} € &nbsp;
    //           </span>
    //           <span className="line-through text-gray-600 font-normal text-xl dark:text-white">
    //             {price !== "0.00"
    //               ? `${
    //                   slug !== "relecture-cv-ou-lettre-de-motivation" &&
    //                   category === "Coaching"
    //                     ? price
    //                     : price
    //                 } €`
    //               : "Gratuit"}
    //           </span>
    //         </div>
    //       ) : (
    //         <div>
    //           <span className="text-xl font-bold dark:text-white">
    //             {price !== "0.00"
    //               ? `${
    //                   slug !== "relecture-cv-ou-lettre-de-motivation" &&
    //                   category === "Coaching"
    //                     ? price
    //                     : price
    //                 } €`
    //               : "Gratuit"}
    //             &nbsp;
    //           </span>
    //           <span className="font-normal text-black dark:text-white text-sm"></span>
    //           <br />
    //         </div>
    //       )}
    //       <Button
    //         text={"Voir plus"}
    //         onClick={(e) => {
    //           e.preventDefault();
    //           navigate(`/product/${slug}`);
    //         }}
    //         className="text-3xl"
    //       ></Button>
    //     </div>
    //   </div>
    // </div>
  );
}

export default Card;

import { Link } from "react-router-dom";


function ProductTabs({tabsRoutes}) {

    const arrayTabsRoutes = Object.entries(tabsRoutes)
  return (
<div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
    <ul className="flex -mb-px">
        {arrayTabsRoutes.map(([route, name], index) => {
            return (
            <li key={index} className="me-2">
            <Link to={route} className="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300">{name}</Link>
        </li>
            )
        })}
    </ul>
</div>

  );
}

export default ProductTabs;

function Button({iconClassName='', text='', onClick, iconSvg = '', textAfter = '', hover, className = '', styleIcon, reverse, type = "button", disabled = false}) {
  return (
    <button onClick={onClick} type={type} disabled={disabled} className={`${disabled ? 'bg-gray-500' : 'bg-current-color-2 hover:bg-current-color-2'} ${!reverse && "text-white"}   font-medium rounded-lg text-sm p-2.5 text-center flex items-center gap-2 ${className} dark:bg-current-color-2 dark:hover:bg-current-color-2 border border-transparent ${hover && 'hover:bg-white hover:text-current-color-2 hover:border-current-color-2 dark:hover:bg-white'} ${reverse && 'text-current-color-2 border border-current-color-2 bg-transparent hover:text-white'} `}>
        {text !== '' && <span>{text}</span>}
        {iconClassName !== '' && <i className={iconClassName} style={styleIcon}></i>}
        {iconSvg !== '' && iconSvg}
        {textAfter !== '' && <span>{textAfter}</span>}
        <span className="sr-only">{text}{textAfter}</span>
    </button>
  );
}

export default Button;

export const getRandomElementsFromArray = (array, numElements) => {
    const copyArray = array.slice();
    const randomElements = [];

    while (copyArray.length > 0 && randomElements.length < numElements) {
        const randomIndex = Math.floor(Math.random() * copyArray.length);
        const randomElement = copyArray.splice(randomIndex, 1)[0];
        randomElements.push(randomElement);
    }

    return randomElements;
}
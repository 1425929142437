import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../axios/axiosInstance';


/**
 * Asynchronous action to get product by current slug
 * @param {string} slug: product's slug
 */
const actionGetCurrentProduct = createAsyncThunk(
  'getCurrentProduct',
  async ({ slug }, thunkAPI) => {
    try {
      const result = await axiosInstance.get(`/products-by-slug/${slug}`);
      return result.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export default actionGetCurrentProduct;
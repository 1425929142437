import { useState } from "react";
import UnderlineTitle from "./UnderlineTitle";

function TabsPresentation() {
  const [currentTab, setCurrentTab] = useState(1);
  return (
    <div className="w-3/4  xl:w-full  m-auto mt-20 xl:h-[620px] relative">
      <UnderlineTitle
        string={"Mes missions"}
        className={"text-center xl:hidden"}
      />
      <ul className="flex flex-wrap mt-8 xl:absolute xl:right-0  xl:w-2/3 font-medium text-center text-gray-500  dark:border-gray-700 dark:text-gray-400">
        <li className="me-2 xl:w-2/5">
          <button
            onClick={() => {
              setCurrentTab(1);
            }}
            className={`w-full text-xs min-[450px]:text-base xl:text-lg uppercase xl:w-3/4 inline-block p-4  rounded-t-lg  ${
              currentTab === 1
                ? "bg-current-color-2 text-gray-100  dark:text-gray-100"
                : "bg-gray-100 text-current-color-2 dark:text-white dark:bg-gray-800"
            }`}
          >
            Accompagnement
          </button>
        </li>
        <li className="me-2 xl:w-2/5">
          <button
            onClick={() => {
              setCurrentTab(2);
            }}
            className={`w-full text-xs min-[450px]:text-base xl:text-lg uppercase xl:w-3/4 inline-block p-4  rounded-t-lg   ${
              currentTab === 2
                ? "bg-current-color-2 text-gray-100  dark:text-gray-100"
                : "bg-gray-100 text-current-color-2 dark:text-white dark:bg-gray-800"
            }`}
          >
            Ebook
          </button>
        </li>
      </ul>
      <div className="p-4 bg-current-color-2 w-full h-fit xl:absolute xl:top-20 rounded-r-lg rounded-bl-lg">
        <div className="m-4 text-xs sm:text-sm lg:text-base text-justify text-white xl:w-2/3 xl:ml-auto">
          {currentTab === 1 && (
            <span>
              Dans un environnement professionnel de plus en plus complexe et
              exigeant, maîtriser l'art de la négociation salariale est
              essentiel pour propulser ta carrière et saisir toutes les
              opportunités qui s'offrent à toi. <br />
              <br />
              C'est pourquoi j'ai créé un coaching spécifiquement conçu pour te
              guider dans cette démarche cruciale. <br />
              <br />
              Imagine pouvoir négocier ton salaire avec assurance et obtenir la
              rémunération que tu mérites. Avec mon soutien, ce rêve peut
              devenir réalité. Mon engagement est de t’offrir un accompagnement
              expert et personnalisé pour chaque étape de ta négociation
              salariale. <br />
              <br />
              Voici ce que tu peux attendre de notre collaboration :
              <ul className="list-disc ps-6">
                <li key="item1">
                  Un soutien personnalisé : je mettrai à ta disposition mes
                  compétences et mon expérience pour t'aider à élaborer une
                  stratégie de négociation parfaitement adaptée à ta situation.
                </li>
                <li key="item2">
                  Des résultats concrets : il est conçu pour te garantir des
                  résultats tangibles et pour t’aider à atteindre tes objectifs
                  financiers et professionnels.
                </li>
                <li key="item3">
                  Un accompagnement total : je serai à tes côtés tout au long du
                  processus, prêt à t’aider à chaque étape et à te soutenir bien
                  au-delà pour assurer ton succès.
                </li>
              </ul>
              Ne laisse pas passer l'opportunité de transformer ta carrière et
              d'obtenir le salaire que tu mérites. Ensemble, nous ferons de
              chaque négociation un moment décisif pour ton avenir
              professionnel. <br />
              <br />
              Réserve dès maintenant ton accompagnement et prends le contrôle de
              ta carrière !{" "}
            </span>
          )}
          {currentTab === 2 && (
            <span>
              Dans un environnement professionnel de plus en plus exigeant,
              maîtriser l'art de la négociation salariale est crucial pour
              avancer dans ta carrière et obtenir la rémunération que tu
              mérites. C'est pourquoi j'ai créé une série d'ebooks spécialement
              conçus pour te guider dans ce domaine essentiel. <br />
              <br />
              Imagine pouvoir accéder à des conseils d'experts, des stratégies
              éprouvées et des outils pratiques pour négocier ton salaire avec
              confiance et efficacité, à petit prix. Avec mes ebooks, tu auras
              toutes les clés en main pour transformer tes négociations
              salariales et atteindre tes objectifs financiers. <br />
              <br />
              Voici ce que tu découvriras dans mes ebooks :
              <ul className="list-disc ps-6">
                <li key="item4">
                  Des stratégies efficaces : apprends les techniques de
                  négociation les plus efficaces, adaptées à différents
                  contextes professionnels. Chaque ebook est rempli de conseils
                  pratiques pour t'aider à te préparer et à aborder les
                  discussions salariales avec assurance.
                </li>
                <li key="item5">
                  Des études de cas réels : profite d’exemples concrets et
                  d’études de cas qui te montreront comment appliquer les
                  stratégies à des situations réelles, afin de mieux comprendre
                  les nuances de la négociation.
                </li>
                <li key="item6">
                  Des outils pratiques : accède à des outils et des modèles pour
                  t'aider à structurer tes demandes, évaluer tes succès et
                  préparer tes arguments de manière convaincante.
                </li>
              </ul>
              Ces ebooks sont conçus pour te fournir des ressources précieuses
              et t’aider à obtenir les résultats que tu désires. Avec ces
              guides, tu seras prêt à transformer chaque opportunité de
              négociation en un succès tangible. <br />
              <br />
              N’attends plus pour prendre le contrôle de ta carrière !
              Télécharge dès maintenant mes ebooks et commence à préparer ta
              prochaine négociation salariale avec assurance.
            </span>
          )}
        </div>
      </div>
      <img
        className="hidden absolute object-cover xl:block xl:left-5 h-full xl:top-1/2 xl:-translate-y-1/2 xl:w-1/4"
        alt="presentation rh"
        src="https://firebasestorage.googleapis.com/v0/b/boostacarriere-2679a.appspot.com/o/files%2Fhome-presentation.jpg?alt=media&token=a980fdbd-dd7b-4f61-98d0-fdd8c21f7523"
      />
    </div>
  );
}

export default TabsPresentation;

import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div className="dark:bg-gray-900 m-auto max-w-screen-xl h-[60vh] relative">
    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border border-1 border-gray-700 dark:border-gray-500 rounded-lg p-8">
      <div className="m-auto lg:flex items-center gap-10">
        <p className="text-center lg:text-left font-semibold text-red-500 text-9xl dark:text-red-600">404</p>
        <div>
        <p className="text-center lg:text-left mt-4 lg:mt-0">
          <i className="fa-solid fa-triangle-exclamation text-red-500 dark:text-red-600 me-2"></i>
          <span className="text-gray-700 dark:text-white font-bold">Oops ! La page n'a pas été trouvée.</span>
        </p>
        <p className="text-gray-700 dark:text-white mt-2 text-center lg:text-left">Tu peux retourner à <Link to='/' className="underline">l'accueil</Link> </p>
        </div>
      </div>
    </div>
    </div>
    
  );
}

export default NotFound;

import { createAction, createReducer } from '@reduxjs/toolkit';
import actionPaymentIntent from '../../asyncActions/paymentIntend';
import { toast } from 'sonner';

const initialState = {
  cart: [// Objet avec : name, picture, price et slogan
  ],
  clientSecret: '',
  CONNECTED_ACCOUNT_ID: '',
  totalAmount: 0,
  isLoading: false,
  orderAmount: ''
};

export const calculateTotalAmount = createAction('CALCULATE_TOTAL_AMOUNT');
export const addToCart = createAction('ADD_TO_CART');
export const removeToCart = createAction('REMOVE_TO_CART');
export const checkLocalStorage = createAction('CHECK_LOCALSTORAGE');

const cartReducer = createReducer(initialState, (builder) => {
    builder.addCase(calculateTotalAmount, (state) => {
        const prices = state.cart.map(product => product.product.price * 100);
        if(prices.length > 0){
          state.totalAmount = (prices.reduce((acc, current) => acc + current, 0) / 100);
        } else {
          state.totalAmount = 0;
        }
    })
    .addCase(checkLocalStorage, (state) => {
      if(localStorage.getItem('cartItem')){
        const cartInLocalStorage = JSON.parse(localStorage.getItem('cartItem'));
        state.cart = cartInLocalStorage;
      }
    })
    .addCase(addToCart, (state, action) => {
      if(localStorage.getItem('cartItem')){
        const cartInLocalStorage = JSON.parse(localStorage.getItem('cartItem'));
        cartInLocalStorage.push(action.payload);
        localStorage.setItem('cartItem', JSON.stringify(cartInLocalStorage));
        state.cart = cartInLocalStorage;
      } else {
        localStorage.setItem('cartItem', JSON.stringify([action.payload]));
        state.cart.push(action.payload);
      }

    })
    .addCase(removeToCart, (state, action) => {
      const cartInLocalStorage = JSON.parse(localStorage.getItem('cartItem'));
      const newLocalStorage = cartInLocalStorage.filter((item, index) => index !== action.payload);
      localStorage.setItem('cartItem', JSON.stringify(newLocalStorage));
      state.cart = newLocalStorage;
    })
    .addCase(actionPaymentIntent.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(actionPaymentIntent.fulfilled, (state, action) => {
      state.clientSecret = action.payload.clientSecret;
      state.orderAmount = action.payload.orderAmount;
      state.isLoading = false;
    })
    .addCase(actionPaymentIntent.rejected, (state, action) => {
      toast.error(action.payload)
      state.isLoading = false;
    })
});

export default cartReducer;
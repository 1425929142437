import { createAction, createReducer } from '@reduxjs/toolkit';

const initialState = {
  isLogged: false,
  currentUser: ''
};


export const createCustomerCurrentUser = createAction('CUSTOMER_CURRENT_USER');

const userReducer = createReducer(initialState, (builder) => {
  builder.addCase(createCustomerCurrentUser, (state, action) => {
    state.currentUser = action.payload
  })
});

export default userReducer;
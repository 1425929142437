import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../axios/axiosInstance';


/**
 * Asynchronous action to get all questions in the DB
 */
const actionGetQuestions = createAsyncThunk(
  'getQuestions',
  async (_, thunkAPI) => {
    try {
      const result = await axiosInstance.get(`/questions`);
      return result.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export default actionGetQuestions;
function Loader() {
  return (
  <div className="absolute h-full w-full top-0 z-[200] left-0 bg-gray-700 opacity-90">
    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
      <div className="border-gray-300 h-20 w-20  animate-spin rounded-full border-8 border-t-blue-600" />
    </div>
  </div>
  );
}

export default Loader;

import {  createReducer } from '@reduxjs/toolkit';
import { toast } from 'sonner';
import actionGetAllAppointments from '../../asyncActions/getAllAppointments';

const initialState = {
  isLoading: false,
  allAppointments: []
};


const appointmentsReducer = createReducer(initialState, (builder) => {builder
  // Get all appointments in the DB
  .addCase(actionGetAllAppointments.pending, (state) => {
    state.isLoading = true
  }) 
  .addCase(actionGetAllAppointments.fulfilled, (state, action) => {
    state.allAppointments = action.payload;
    state.isLoading = false;
  }) 
  .addCase(actionGetAllAppointments.rejected, (state, action) => {
    toast.error(action.payload);
    state.isLoading = false;
  }) 
});

export default appointmentsReducer;
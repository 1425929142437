import UnderlineTitle from "./UnderlineTitle";

function ProductCurrentProblems({ currentProblems }) {
  return (
    <section className="my-8 p-5 lg:p-0" id="problemes">
      <UnderlineTitle string={"As-tu déjà vécu ça ?"}></UnderlineTitle>
      <ul className="space-y-4 text-left text-gray-500 dark:text-gray-200">
        {currentProblems &&
          currentProblems.map((currentProblem, index) => (
            <li
              key={index}
              className="flex items-center space-x-3 rtl:space-x-reverse"
            >
              <svg
                className="flex-shrink-0 w-3.5 h-3.5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
              >
                <path
                  fill="#ff0000"
                  d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                />
              </svg>
              <span>{currentProblem.problem}</span>
            </li>
          ))}
      </ul>
    </section>
  );
}

export default ProductCurrentProblems;

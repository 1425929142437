import axios from 'axios';

// on créé une instance de axios, qu'on va utiliser à la place du axios classique
// au lieu de faire axios.get on va faire axiosInstance.get
let axiosInstance;
if(process.env.REACT_APP_DEBUG === "true"){
  axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_LOCAL_BASE_URL_API,
  });
} else {
  axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL_API,
  });
}


export default axiosInstance;
import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../axios/axiosInstance";

/**
 * Asynchronous action to get all ebooks in the DB
 */
const actionGetAllEbooks = createAsyncThunk(
  "getAllEbooks",
  async (_, thunkAPI) => {
    try {
      const result = await axiosInstance.get(`/products?category=ebooks`);
      return result.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export default actionGetAllEbooks;

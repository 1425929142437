import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../axios/axiosInstance';


/**
 * Asynchronous action to create a new order for a free product
 * @param {string} name: user's name
 * @param {string} mail: user's mail
 * @param {number} productId : product's id
 */
const actionFreeProduct = createAsyncThunk(
  'freeProduct',
  async ({ name, mail,  productId, termsAccepted}, thunkAPI) => {
    try {
      const result = await axiosInstance.post(`/free-product`, {name, mail, productId, termsAccepted});
      return result.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export default actionFreeProduct;
import { Link } from "react-router-dom";

/**
 * EbookCard component for Ebooks
 * @param {string} slug: product's slug
 * @param {string} picture: product's picture url
 *
 */
function EbookCard({ slug, picture }) {
  return (
    <div className="w-1/2 m-auto h-80 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
      <Link to={`/product/${slug}`}>
        <img
          className="rounded-lg h-full w-full object-cover"
          src={picture}
          alt="product"
        />
      </Link>
    </div>
  );
}

export default EbookCard;

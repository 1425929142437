import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../axios/axiosInstance';


/**
 * Asynchronous action for send an email to boostacarriere admin
 * @param {string} email
 * @param {string} subject
 * @param {string} message
 * 
 */
const actionContactForm = createAsyncThunk(
  'ContactForm',
  async ({ email, subject, message }, thunkAPI) => {
    try {
      const result = await axiosInstance.post(`/contact`, {email, subject, message});
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export default actionContactForm;
import { Link, useNavigate } from "react-router-dom";
import Button from "../micro_components/Button";
import InputIcon from "../micro_components/InputIcon";
import Mail from "../micro_components/svg/Mail";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import actionContactForm from "../asyncActions/contactForm";
import { useEffect } from "react";
import Loader from "../micro_components/Loader";

function Contact() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const isSending = useSelector((state) => state.contact.isSending);
  const isLoading = useSelector((state) => state.contact.isLoading);
  
  const handleSubmitForm = (e) => {
    e.preventDefault();
    if(email.length > 0 && subject.length > 0 && message.length > 0){
      dispatch(actionContactForm({email, subject, message}));
    } 
  }

  useEffect(() => {
    if(isSending){
      setEmail("");
      setMessage("");
      setSubject("");
    }
  }, [isSending])
  

  return (
<section className="bg-white dark:bg-gray-900">
  <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
      <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-current-color-2 dark:text-current-color ">Contacte nous</h2>
      <p className="font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">Un problème, une question, envie de nous envoyer un message d’amour ? N’hésitez pas à utiliser ce formulaire pour prendre contact avec moi !</p>
      <p className="mt-3 mb-8 lg:mb-16 font-semibold text-center text-gray-500 dark:text-gray-300 sm:text-xl"> Avant de me contacter, vérifie si tu ne trouves la réponse à ta question sur la  <Link className="underline text-current-color-2 dark:text-current-color" to="/faq">FAQ</Link></p>
      {isLoading ? ( 
        <Loader />
      ) : (
        <form onSubmit={(e) => handleSubmitForm(e)} className="space-y-8">
          <div>
              <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Ton email</label>
              <InputIcon placeholder={"jeveux@unjob.fr"} id={"email"} iconSvg={<Mail />} type={"email"} required={true} onChange={setEmail} value={email}/>
          </div>
          <div>
              <label htmlFor="subject" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Objet du message</label>
              <input type="text" id="subject" className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="Problème de connexion" required onChange={(e) => setSubject(e.target.value)} value={subject}/>
          </div>
          <div className="sm:col-span-2">
              <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Ton message</label>
              <textarea id="message" rows="6" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Ecris ton message..." onChange={(e) => setMessage(e.target.value)} value={message}></textarea>
          </div>
          <p className="italic dark:text-white">Les informations saisies dans ce formulaire ne sont pas stockées par Boostacarriere mais peuvent être utilisées pour vous contacter. </p>
          <Button text="Envoyer mon message" type="submit" className="m-auto"/>
      </form>
      )}
      
  </div>
</section>
    
  );
}

export default Contact;

import { useNavigate } from "react-router-dom";
import FaqItem from "../components/FaqItem";
import Button from "../micro_components/Button";
import {useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import actionGetQuestions from "../asyncActions/getQuestions";

function Faq() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const questions = useSelector((state) => state.questions.questions);
  const evenQuestions = questions.filter((_, index) => index % 2 !== 0);
  const oddQuestions = questions.filter((_, index) => index % 2 === 0);

  useEffect(() => {
    dispatch(actionGetQuestions());
  }, [dispatch])
  return (
    <section className="relative overflow-hidden bg-white pb-12 pt-20 dark:bg-dark lg:pb-[90px] lg:pt-[120px] dark:bg-gray-800">
      <div className="container mx-auto">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="mx-auto mb-[60px] max-w-[520px] text-center lg:mb-20">
              <span className="mb-2 block text-lg font-semibold text-primary dark:text-white">
                FAQ
              </span>
              <h2 className="mb-4 text-3xl font-bold text-dark dark:text-white sm:text-[40px]/[48px]">
                Tu as une question ? Trouve ta réponse
              </h2>
            </div>
          </div>
        </div>

        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4 lg:w-1/2">
            {oddQuestions.map((question, index) => 
              <FaqItem key={index}
                question={question.question}
                response={question.response}
              />
            )}
          </div>
          <div className="w-full px-4 lg:w-1/2">
            {evenQuestions.map((question, index) => 
              <FaqItem key={index}
                question={question.question}
                response={question.response}
              />
            )}
          </div>
          <Button onClick={() => navigate("/contact")} className="m-auto" text="Je n'ai pas trouvé de réponse" />
        </div>
      </div>

      <div className="absolute bottom-0 right-0 z-[-1]">
        <svg
          width="1440"
          height="886"
          viewBox="0 0 1440 886"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.5"
            d="M193.307 -273.321L1480.87 1014.24L1121.85 1373.26C1121.85 1373.26 731.745 983.231 478.513 729.927C225.976 477.317 -165.714 85.6993 -165.714 85.6993L193.307 -273.321Z"
            fill="url(#paint0_linear)"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="1308.65"
              y1="1142.58"
              x2="602.827"
              y2="-418.681"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#3056D3" stopOpacity="0.36" />
              <stop offset="1" stopColor="#F5F2FD" stopOpacity="0" />
              <stop offset="1" stopColor="#F5F2FD" stopOpacity="0.096144" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </section>
    
  );
}

export default Faq;

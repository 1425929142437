import { createReducer } from '@reduxjs/toolkit';
import actionPostNewsletterRegister from '../../asyncActions/newsletterRegistersStore';
import { toast } from 'sonner';


const initialState = {
    isLoading: false,
    isCreated: false
};


const newsletterReducer = createReducer(initialState, (builder) => {builder
  // Create a new newsletter register in the DB
  .addCase(actionPostNewsletterRegister.pending, (state) => {
    state.isLoading = true
    state.isCreated = false
}) 
  .addCase(actionPostNewsletterRegister.fulfilled, (state) => {
    toast.success('Ton inscription à la newsletter a bien été enregistré');
    state.isLoading = false;
    state.isCreated = true
})
  .addCase(actionPostNewsletterRegister.rejected, (state, action) => {
    state.isLoading = false;
    toast.error(action.payload);
  })
});

export default newsletterReducer;
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import LogoName from "../micro_components/LogoName";
import Button from "../micro_components/Button";
import Soleil from "../micro_components/svg/Soleil";
import Lune from "../micro_components/svg/Lune";
import actionGetAllEbooks from "../asyncActions/getAllEbooks";
import actionGetAllCoachings from "../asyncActions/getAllCoachings";

function Navbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isDark, setIsDark] = useState(false);

  const navbarRef = useRef(null);
  const blocLinkNavbarRef = useRef(null);
  const dropdownCoachingRef = useRef(null);

  const allCoachings = useSelector((state) => state.products.allCoachings);
  const allEbooks = useSelector((state) => state.products.allEbooks);

  // Get all products for dropdown
  useEffect(() => {
    dispatch(actionGetAllEbooks());
    dispatch(actionGetAllCoachings());
  }, [dispatch]);

  // Use effect for first render : add event listener on page scroll for navbar animation
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 80) {
        navbarRef.current.classList.add("fixed", "top-0", "w-full", "z-50");
      } else {
        navbarRef.current.classList.remove("fixed", "top-0", "w-full", "z-50");
      }
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [dispatch]);

  const handleCloseMenu = () => {
    blocLinkNavbarRef.current.classList.toggle("hidden");
  };

  return (
    <nav
      ref={navbarRef}
      className="bg-white border-gray-200 dark:bg-gray-900 h-[8vh] "
    >
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <LogoName imgSize={10}></LogoName>
        <div className="flex items-center  space-x-3 md:space-x-0 rtl:space-x-reverse">
          <button
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            onClick={() => blocLinkNavbarRef.current.classList.toggle("hidden")}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
        </div>
        <div
          ref={blocLinkNavbarRef}
          className="items-center justify-between hidden w-full lg:flex lg:w-3/4  lg:order-1 z-50 lg:z-10"
          id="navbar-user"
        >
          <ul className="flex flex-col font-medium p-4 lg:p-0 mt-4 border border-b-0 border-gray-100 rounded-t-lg bg-gray-50 lg:space-x-8 rtl:space-x-reverse lg:flex-row lg:mt-0 lg:border-0 lg:bg-white dark:bg-gray-800 lg:dark:bg-gray-900 dark:border-gray-700">
            <li>
              <NavLink
                to="/"
                className="block py-2 px-3 text-white bg-current-color-2 rounded lg:bg-transparent lg:text-current-color-2 lg:p-0 lg:dark:text-current-color-2"
                aria-current="page"
                onClick={() => handleCloseMenu()}
              >
                Accueil
              </NavLink>
            </li>
            <li>
              <button
                id="dropdownNavbarLink"
                data-dropdown-toggle="dropdownCoaching"
                className="flex items-center justify-between w-full py-2 px-3 text-gray-900 rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-current-color-2 lg:p-0 lg:w-auto dark:text-white lg:dark:hover:text-current-color-2 dark:focus:text-white dark:border-gray-700 dark:hover:bg-gray-700 lg:dark:hover:bg-transparent"
              >
                Coachings
                <svg
                  className="w-2.5 h-2.5 ms-2.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>
              <div
                id="dropdownCoaching"
                ref={dropdownCoachingRef}
                className="hidden font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-fit dark:bg-gray-700 dark:divide-gray-600"
              >
                <ul
                  className="py-2 text-sm text-gray-700 dark:text-gray-400"
                  aria-labelledby="dropdownLargeButton"
                >
                  {allCoachings.map((coaching) => (
                    <li key={coaching.id}>
                      <NavLink
                        onClick={() => handleCloseMenu()}
                        to={`/product/${coaching.slug}`}
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        {coaching.title}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            </li>
            <li>
              <button
                id="dropdownNavbarLink"
                data-dropdown-toggle="dropdownEbook"
                className="flex items-center justify-between w-full py-2 px-3 text-gray-900 rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-current-color-2 lg:p-0 lg:w-auto dark:text-white lg:dark:hover:text-current-color-2 dark:focus:text-white dark:border-gray-700 dark:hover:bg-gray-700 lg:dark:hover:bg-transparent"
              >
                Ebooks
                <svg
                  className="w-2.5 h-2.5 ms-2.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>
              <div
                id="dropdownEbook"
                className="z-10 hidden font-normal w-fit bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600"
              >
                <ul
                  className="py-2 text-sm text-gray-700 dark:text-gray-400"
                  aria-labelledby="dropdownLargeButton"
                >
                  {allEbooks.map((ebook) => (
                    <li key={ebook.id}>
                      <NavLink
                        onClick={() => {
                          handleCloseMenu();
                        }}
                        to={`/product/${ebook.slug}`}
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        {ebook.title}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            </li>
            <li>
              <NavLink
                onClick={() => handleCloseMenu()}
                to="/a-propos"
                className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 lg:hover:bg-transparent lg:hover:text-current-color-2 lg:p-0 dark:text-white lg:dark:hover:text-current-color-2 dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
              >
                À propos
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={() => handleCloseMenu()}
                to="/contact"
                className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 lg:hover:bg-transparent lg:hover:text-current-color-2 lg:p-0 dark:text-white lg:dark:hover:text-current-color-2 dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
              >
                Contact
              </NavLink>
            </li>
          </ul>
          <div className="flex items-center justify-end bg-gray-50 border border-t-0 rounded-b-lg p-4 border-gray-100 lg:bg-white lg:border-0 lg:p-0 dark:bg-gray-800 lg:dark:bg-gray-900 dark:border-gray-700">
            <Button
              iconSvg={isDark ? <Lune /> : <Soleil />}
              onClick={() => {
                document.body.classList.toggle("dark");
                setIsDark(!isDark);
              }}
              className="me-2"
            ></Button>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
